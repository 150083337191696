import { Button, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Text } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { Tag, usePageData } from '../../../lib/hooks/PageDataContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../funnel/Funnel';
import { useI18n } from '../../../lib/hooks/I18n';
import axios from '../../../lib/api/axios';
import { useState } from 'react';

const MachineIDStep = () => {
  const { t, i18n } = useI18n();
  const { stepValues, goForward, goBack, values, setFunnel, funnel } = useFunnel();
  const { owner, setPrefill } = usePageData();
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object({
    machine_id: yup.string().when('$required', (required, schema) => required ? schema.required(t('fields.machine_id.validations.required')) : schema)
  });

  const { register, getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: stepValues(),
    context: {
      required: owner.require_machine_id_title_flg || values.refund_requested && owner?.require_machine_id_in_refund_flg,
    }
  });

  const [onSubmit] = useThrottle(async (values: any) => {
    if (!values.machine_id) {
      goForward(values);

      return;
    }

    try {
      setIsLoading(true);
      const data = await axios<Tag>(`/api/owner/${owner.company_url_slug}/tag/${values.machine_id}`);

      setPrefill({ tag: data });
    } catch (e) {
      setPrefill({ tag: null });
    } finally {
      setIsLoading(false);
    }

    setTimeout(() => {
      setFunnel(funnel); // Recomputes the completed steps
      goForward(values);
    }, 0)
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="machine_id" isInvalid={!!errors.machine_id}>
        <FormLabel variant="heavy">{ t('fields.machine_id.label') }</FormLabel>
        { i18n.exists('fields.machine_id.text') && <Text fontSize={13} color="gray" mt={-2} mb={3}>{ t('fields.machine_id.text') }</Text>}

        <Input
          type="text"
          maxLength="150"
          defaultValue={getValues('machine_id')}
          {...register('machine_id')}
        />

        { errors.machine_id ?
          <FormErrorMessage>{ errors.machine_id.message }</FormErrorMessage> :
          <FormHelperText>
            {
              owner?.require_machine_id_title_flg || values.refund_requested && owner?.require_machine_id_in_refund_flg ?
                t('fields.machine_id.aside.required_text') :
                t('fields.machine_id.aside.optional_text')
            }
          </FormHelperText>
        }
      </FormControl>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          isLoading={isLoading}
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default MachineIDStep;
