import { Button, Flex, FormControl, FormErrorMessage, Text, Heading, Input } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useThrottle } from 'rooks';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';
import { useValidateEmail } from '../../../../lib/hooks/UseValidateEmail';

const RefundInteracDetails = () => {
  const { t } = useI18n();
  const { stepValues, goForward, goBack } = useFunnel();
  const { validateEmail, isLoading: isCheckingEmail } = useValidateEmail();

  const schema = yup.object().shape({
    refund_email: yup.string().nullable().required(t('common.validations.required')).email(t('common.validations.email')),
  });

  const { register, getValues, setError, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: stepValues()
  });

  const [onSubmit] = useThrottle(async (data: any) => {
    data.interac_contact_method = 'email';

    const isValid = await validateEmail(data.refund_email);
    if (isValid) {
      goForward(data);
    } else {
      setError('refund_email', {type: 'email', message: t('common.validations.email')});
    }
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading fontSize="xl" mb="2">{ t('steps.refund_interac_details.title') }</Heading>
      <Text mb="4">{ t('steps.refund_interac_details.text') }</Text>

      <FormControl id="refund_email" isInvalid={!!errors.refund_email}>
        <Input
          type="email"
          maxLength={50}
          placeholder={ t('fields.refund_email.placeholder') }
          defaultValue={getValues('refund_email')}
          {...register('refund_email')}
        />
        <FormErrorMessage>{ errors.refund_email?.message }</FormErrorMessage>
      </FormControl>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          isLoading={isCheckingEmail}
          disabled={isCheckingEmail || isSubmitted && !isValid }
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default RefundInteracDetails;
