import { Box, Button, Divider, Flex, Heading, Spacer, VStack, Text, useDisclosure } from '@chakra-ui/react';
import { Tag, usePageData } from 'lib/hooks/PageDataContext';
import TagSearch from './TagSearch';
import { useI18n } from '../../lib/hooks/I18n';
import ReactHtmlParser from 'react-html-parser';

type Props = {
  tag: Tag;
  onTagUpdate: (tag: Tag) => void;
};

const TagDetail = ({ tag, onTagUpdate }: Props) => {
  const { t } = useI18n();
  const { settings } = usePageData();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleTagUpdate = (tag: Tag) => {
    onTagUpdate(tag);
    onClose();
  };

  const hideDetails = settings['content_tag_details-hidden'];
  const address = tag.address?.business_status ? `${tag.address?.name}, ${tag.address?.formatted_address}` : tag.address?.formatted_address;

  let defaultsHtml = settings['content_tag_details-format'] || '<strong>{tag.name}</strong>';

  if (!address) {
    defaultsHtml = defaultsHtml.replaceAll('{common.words.at} {tag.address}', '');
  }

  defaultsHtml = defaultsHtml.replaceAll('{tag.name}', tag.name || '');
  defaultsHtml = defaultsHtml.replaceAll('{tag.account_name}', tag.account_name || '');
  defaultsHtml = defaultsHtml.replaceAll('{tag.location_id}', tag.location_id || '');
  defaultsHtml = defaultsHtml.replaceAll('{tag.location_name}', tag.location_name || '');
  defaultsHtml = defaultsHtml.replaceAll('{tag.identifier}', `${tag.identifier}` || '');
  defaultsHtml = defaultsHtml.replaceAll('{tag.description}', tag.description || '');
  defaultsHtml = defaultsHtml.replaceAll('{tag.address}', address || '');
  defaultsHtml = defaultsHtml.replaceAll('{common.words.at}', t('common.words.at'));
  defaultsHtml = defaultsHtml.replaceAll('{tag.type}', tag.tag_type?.type || '');

  const details = ReactHtmlParser(defaultsHtml);

  return (
    <VStack
        spacing="0"
        mb="4"
        bgColor="brand.100"
        borderRadius="2xl"
        alignItems="left"
        shadow={ isOpen ? 'lg' : 'none' }
        divider={<Divider borderColor="brand.600" variant="dashed" />}
    >
        <Flex alignItems="center" px="5" py="3">
            <Flex alignItems="baseline">
                <Heading size="sm" mr="2" fontWeight="bold">ID:</Heading>
                <Heading size="lg" variant="mono">{tag.identifier}</Heading>
            </Flex>
            <Spacer/>
            <Button variant={ isOpen ? 'solid' : 'outline' } size="sm" onClick={onToggle}>
              { isOpen ? t('common.buttons.cancel') : t('common.buttons.change') }
            </Button>
        </Flex>
        {
          !isOpen ? (!hideDetails && <Box px="5" py="2">
            <Text fontSize="sm">{ details }</Text>
          </Box>) : <TagSearch tag={tag} onTagUpdate={handleTagUpdate} />
        }
    </VStack>
  );
};

export default TagDetail;
