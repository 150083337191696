import {
  Button,
  Flex,
  Heading,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { IconAlertTriangle, IconBell } from '@tabler/icons-react';
import { useI18n } from '../../lib/hooks/I18n';
import { Notice, usePageData } from '../../lib/hooks/PageDataContext';
import dayjs from 'dayjs';
import { useState } from 'react';
import { RawHtml } from '../layout/RawHtml';

type Props = {};

const Notices = ({ }: Props) => {
  const { t, locale } = useI18n();
  const { notices } = usePageData();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [ viewingNotice, setViewingNotice ] = useState<Notice>();
  const [ showAllNotices, setShowAllNotices ] = useState<boolean>(false);

  if (!notices || !notices.length) {
    return <></>;
  }

  return <>
    <VStack
        spacing="0"
        mb="4"
        pb="2"
        bgColor="brand.100"
        borderRadius="2xl"
        alignItems="left"
    >
      <Flex alignItems="center" px="5" py="3" mb={2} bgColor="brand.600" borderTopRadius="2xl" gap="3">
        <Heading size="sm" mr="2" fontWeight="bold" color="white">{ t('components.notices.title') }</Heading>
      </Flex>

      { notices?.map((notice, index) => {
        if (index >= 3 && !showAllNotices) {
          return;
        }

        return <Flex px="4" py="2" alignItems="start" gap="3.5" key={notice.id}>
          { notice.type === 'Notice' && <IconAlertTriangle size={36} height={20} width={20} strokeWidth={1.5} /> }
          { notice.type === 'Announcement' && <IconBell size={36} height={20} width={20} strokeWidth={1.5} /> }
          <Text fontSize="sm" flex={1}>
            <strong>{dayjs(notice.start_date).format('MMM DD, hh:mma')}.</strong> { notice.translations?.title?.[locale] }
          </Text>
          <Button variant="outline" colorScheme="white" size="sm" px={5}
                  onClick={() => {
                    setViewingNotice(notice);
                    onOpen();
                  }}>
            { t('common.buttons.view') }
          </Button>
        </Flex>;
      })}

      { notices.length > 3 && !showAllNotices && <>
        <Flex px="5" py="2" alignItems="center" gap="3">
          <Button variant="outline" colorScheme="white" size="sm" px={4} onClick={() => setShowAllNotices(true)}>Show all</Button>
          <Text fontSize="13" fontWeight="medium">{notices.length - 3} { t('common.clauses.more_messages') }</Text>
        </Flex>
      </>}
    </VStack>

    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="2xl" ml="3" mr="3">
        <ModalHeader pb={0}>{ viewingNotice?.translations?.title?.[locale] }</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RawHtml html={viewingNotice?.translations?.content?.[locale] ?? ''} />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            { t('common.buttons.close') }
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>;
};

export default Notices;
