import { Heading, Button, Box, Flex, Divider, Text, VStack } from '@chakra-ui/react';
import { usePageData } from 'lib/hooks/PageDataContext';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import Footer from '../../layout/Footer';
import { useFunnel } from '../../funnel/Funnel';
import { DateTime } from 'luxon';
import { useI18n } from '../../../lib/hooks/I18n';
import { useRouter } from 'next/router';
import ReactHtmlParser from 'react-html-parser';
import { RefundFunnelSteps } from '../../funnel/refund-funnel-steps';

const ContinueRefundStep = () => {
  const { t, locale } = useI18n();
  const { query } = useRouter();
  const { goForward, setFunnel } = useFunnel();
  const { owner, refund, settings } = usePageData();
  const tag = refund?.tag;

  if (!refund.payment_method) {
    return <></>;
  }

  const formattedAmount = new Intl.NumberFormat().format(refund.amount);
  const formattedPaymentMethod = refund.payment_method.name ?? t(`common.payment_methods.${refund.payment_method.type}`);
  const formattedDate = DateTime.fromISO(refund.created_at).setLocale(locale).toFormat('EEEE dd, MMMM');

  const handleClick = () => {
    setFunnel(RefundFunnelSteps);
    goForward();
  };

  const goToStart = () => {
    let url = `/${owner.company_url_slug}`;

    if (query.lang) {
      url += `?lang=${query.lang}`;
    }

    return window?.location?.assign(url);
  }

  const hideDetails = settings['content_tag_details-hidden'];
  let details = '';

  if (tag) {
    const address = tag.address?.business_status ? `${tag.address?.name}, ${tag.address?.formatted_address}` : tag.address?.formatted_address;

    let defaultsHtml = settings['content_tag_details-format'] || '<strong>{tag.name}</strong>';

    if (!address) {
      defaultsHtml = defaultsHtml.replaceAll('{common.words.at} {tag.address}', '');
    }

    defaultsHtml = defaultsHtml.replaceAll('{tag.name}', tag.name || '');
    defaultsHtml = defaultsHtml.replaceAll('{tag.account_name}', tag.account_name || '');
    defaultsHtml = defaultsHtml.replaceAll('{tag.location_id}', tag.location_id || '');
    defaultsHtml = defaultsHtml.replaceAll('{tag.location_name}', tag.location_name || '');
    defaultsHtml = defaultsHtml.replaceAll('{tag.identifier}', `${tag.identifier}` || '');
    defaultsHtml = defaultsHtml.replaceAll('{tag.description}', tag.description || '');
    defaultsHtml = defaultsHtml.replaceAll('{tag.address}', address || '');
    defaultsHtml = defaultsHtml.replaceAll('{common.words.at}', t('common.words.at'));
    defaultsHtml = defaultsHtml.replaceAll('{tag.type}', tag.tag_type?.type || '');

    details = ReactHtmlParser(defaultsHtml) as any;
  }

  return (
    <>
      <Heading fontSize="xl" mb="4" fontWeight="bold">{ t('steps.continue_refund.title') }</Heading>

      <VStack
        spacing="0"
        mb="4"
        bgColor="brand.100"
        borderRadius="2xl"
        alignItems="left"
        divider={<Divider borderColor="brand.600" variant="dashed" />}
      >
        <Flex px="5" py="3">
          <Heading size="xs" mr="2" fontWeight="bold">
            ${formattedAmount} {formattedPaymentMethod} { t('common.clauses.purchase_on') } {formattedDate}.
          </Heading>
        </Flex>
        { !hideDetails && tag &&
          <Box px="5" py="2">
              <Text fontSize="sm">{details}</Text>
          </Box>
        }
      </VStack>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={goToStart}>
          { t('common.buttons.cancel') }
        </Button>
        <Button
          variant="solid"
          size="lg"
          type="submit"
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
          onClick={handleClick}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>

      <Footer />
    </>
  );
};

export default ContinueRefundStep
