import { FunnelSteps } from './Funnel';
import {
  collectReversalDetails,
  paymentMethodCollectsAllRefundDetails,
  paymentMethodIs,
  refundMethodIs
} from './help-funnel-steps';

export const RefundFunnelSteps = [
  {
    name: 'continue_refund',
    next: [
      { target: 'refund_amount' }
    ]
  },
  {
    name: 'refund_amount',
    next: [
      {
        target: 'refund_card_details',
        cond: (ctx, values) => collectReversalDetails(ctx, values) && paymentMethodIs(ctx, values, 'Card'),
      },
      {
        target: 'refund_apple_pay_details',
        cond: (ctx, values) => collectReversalDetails(ctx, values) && paymentMethodIs(ctx, values, 'ApplePay')
      },
      {
        target: 'refund_apple_card_details',
        cond: (ctx, values) => collectReversalDetails(ctx, values) && paymentMethodIs(ctx, values, 'AppleCard')
      },
      {
        target: 'refund_google_pay_details',
        cond: (ctx, values) => collectReversalDetails(ctx, values) && paymentMethodIs(ctx, values, 'GooglePay')
      },
      { target: 'refund_method' }
    ]
  },
  {
    name: 'refund_card_details',
    next: [
      { target: 'refund_method', cond: paymentMethodCollectsAllRefundDetails },
      { target: 'success', submit: true }
    ]
  },
  {
    name: 'refund_apple_pay_details',
    next: [
      { target: 'refund_method', cond: paymentMethodCollectsAllRefundDetails },
      { target: 'success', submit: true }
    ]
  },
  {
    name: 'refund_apple_card_details',
    next: [
      { target: 'refund_method', cond: paymentMethodCollectsAllRefundDetails },
      { target: 'success', submit: true }
    ]
  },
  {
    name: 'refund_google_pay_details',
    next: [
      { target: 'refund_method', cond: paymentMethodCollectsAllRefundDetails },
      { target: 'success', submit: true }
    ]
  },
  {
    name: 'refund_method',
    next: [
      { target: 'refund_paypal', cond: (ctx, values) => refundMethodIs(ctx, values, 'paypal') },
      { target: 'refund_venmo', cond: (ctx, values) => refundMethodIs(ctx, values, 'venmo') },
      { target: 'refund_zelle', cond: (ctx, values) => refundMethodIs(ctx, values, 'zelle') },
      { target: 'refund_details', cond: (ctx, values) => refundMethodIs(ctx, values, 'check') },
      { target: 'refund_details', cond: (ctx, values) => refundMethodIs(ctx, values, 'cash') },
      { target: 'refund_vendor_card', cond: (ctx, values) => refundMethodIs(ctx, values, 'vendorcard') },
      { target: 'refund_interac', cond: (ctx, values) => refundMethodIs(ctx, values, 'interac') },
      { target: 'refund_external_details', cond: (ctx, values) => refundMethodIs(ctx, values, 'external') },
    ]
  },
  {
    name: 'refund_paypal',
    submit: true,
    next: [
      { target: 'success' },
    ]
  },
  {
    name: 'refund_venmo',
    submit: true,
    next: [
      { target: 'success' },
    ]
  },
  {
    name: 'refund_zelle',
    submit: true,
    next: [
      { target: 'success' },
    ]
  },
  {
    name: 'refund_details',
    submit: true,
    next: [
      { target: 'success' },
    ]
  },
  {
    name: 'refund_vendor_card',
    submit: true,
    next: [
      { target: 'success' },
    ]
  },
  {
    name: 'refund_interac',
    next: [
      { target: 'refund_interac_question' },
    ]
  },
  {
    name: 'refund_interac_question',
    next: [
      { target: 'refund_interac_answer' },
    ]
  },
  {
    name: 'refund_interac_answer',
    submit: true,
    next: [
      { target: 'success' },
    ]
  },
  {
    name: 'refund_external_details',
    submit: true,
    next: [
      { target: 'success' }
    ]
  },
  {
    name: 'success',
    next: []
  },
] as FunnelSteps;
