import { FunnelSteps } from './Funnel';
import { RequestType, PaymentMethod } from '../../lib/hooks/PageDataContext';
import { DateTime } from 'luxon';
import allowedPaymentMethods from '../helpers/allowed-payment-methods';

export const refundableTypeOf = (types: RequestType[] | undefined, id: number | null, type: string) => {
  return types?.find(type => type.id == id)?.settings['refundable_type'] === type ?? false;
};

export const getPaymentMethodById = (payment_methods: PaymentMethod[], paymentId) => {
  return payment_methods?.find(method => method.id == paymentId);
}

export const hasPaymentMethodEnabled = ({ owner, prefill }) => {
  const paymentMethods = allowedPaymentMethods(owner, prefill);

  return paymentMethods.length > 0 && owner.settings.refunds_active && owner.settings['refunds_has-pre-auth-enabled'];
};

export const hasNoTag = ({ prefill }, values) => !prefill.tag && !values.tag_id;

export const machineIdRequired = ({ prefill, owner }, values) => {
  return !values.machine_id && !values.tag_id && !prefill.tag && values?.refund_requested && !!owner?.require_machine_id_in_refund_flg;
}

export const refundIsRequired = ({ owner, prefill, settings, requestLink }, values) => {
  return settings.refunds_active && !requestLink?.request?.refund_id && !prefill?.tag?.meta?.refunds_disabled &&
    (refundableTypeOf(owner?.request_types, values?.request_type, 'required') || values?.refund_requested);
}

export const canRequestRefund = ({ owner, prefill, settings, requestLink }, values) => {
  return settings.refunds_active && !requestLink?.request?.refund_id && !prefill?.tag?.meta?.refunds_disabled &&
    refundableTypeOf(owner?.request_types, values?.request_type, 'optional');
}

export const refundMethodIs = ({ owner, settings }, values, type) => {
  return settings.refunds_active &&
    values?.refund_method?.toLowerCase() === type &&
    (refundableTypeOf(owner?.request_types, values?.request_type, 'required') || values?.refund_requested);
}

export const hasPreAuthPeriodExceeded = ({ owner, prefill, requestLink }, values) => {
  const method = getPaymentMethodById(owner?.payment_methods, values?.refund_payment_method);

  if (requestLink?.skip_pre_auth || prefill?.tag?.meta?.pre_auth_disabled) {
    return true;
  }

  if (method?.pre_auth_enabled) {
    const past = DateTime.now().minus({ day: values.refund_days_since_purchase ?? 0 }).startOf('day');

    return Math.abs(past.diffNow('hours').hours) >= method.pre_auth_follow_up_delay;
  }

  return true;
}

export const paymentMethodHasPreAuth = ({ owner, requestLink, prefill }, values) => {
  if (requestLink?.skip_pre_auth || prefill?.tag?.meta?.pre_auth_disabled) {
    return false;
  }

  return getPaymentMethodById(owner?.payment_methods, values?.refund_payment_method)?.pre_auth_enabled || false;
}

export const paymentMethodHasRefundType = ({ owner }, values, type: 'Refund' | 'Reversal') => {
  return getPaymentMethodById(owner?.payment_methods, values?.refund_payment_method)?.pre_auth_refund_method_type === type || false;
}

export const paymentMethodCollectsAllRefundDetails = ({ owner }, values) => {
  return getPaymentMethodById(owner?.payment_methods, values?.refund_payment_method)?.settings['collect_all_refund-details'];
}

export const collectReversalDetails = ({ owner }, values) => {
  return paymentMethodHasRefundType({ owner }, values, 'Reversal') || paymentMethodCollectsAllRefundDetails({ owner }, values);
}

export const paymentMethodIs = ({ owner }, values, type) => {
  return getPaymentMethodById(owner?.payment_methods, values?.refund_payment_method)?.type === type;
}

export const HelpFunnelSteps = [
  {
    name: 'selector',
    next: [
      { target: 'refund_amount', cond: ({ requestLink }) => requestLink?.type === 'Refund' },
      { target: 'request_type' }
    ]
  },
  {
    name: 'request_type',
    next: [
      { target: 'machine_type', cond: hasNoTag },
      { target: 'description' },
    ]
  },
  {
    name: 'machine_type',
    next: [
      { target: 'description' },
    ]
  },
  {
    name: 'description',
    next: [
      { target: 'refund_request', cond: canRequestRefund },
      { target: 'machineID', cond: machineIdRequired },
      { target: 'photo' },
    ]
  },
  {
    name: 'refund_request',
    next: [
      { target: 'machineID', cond: machineIdRequired },
      { target: 'photo' },
    ]
  },
  {
    name: 'machineID',
    next: [
      { target: 'photo' },
    ]
  },
  {
    name: 'photo',
    next: [
      { target: 'refund_amount', cond: refundIsRequired },
      { target: 'contact' },
    ]
  },
  {
    name: 'refund_amount',
    next: [
      { target: 'refund_payment_method', cond: hasPaymentMethodEnabled },
      { target: 'refund_method' }
    ]
  },
  {
    name: 'refund_payment_method',
    next: [
      { target: 'contact', cond: (ctx, values) => paymentMethodIs(ctx, values, 'PayRange') },
      {
        target: 'refund_vendor_card',
        cond: (ctx, values) =>
          paymentMethodHasRefundType(ctx, values, 'Reversal') &&
          paymentMethodIs(ctx, values, 'VendorCard')
      },
      { target: 'refund_payment_date', cond: paymentMethodHasPreAuth },
      {
        target: 'refund_card_details',
        cond: (ctx, values) =>
          collectReversalDetails(ctx, values) &&
          paymentMethodIs(ctx, values, 'Card'),
      },
      {
        target: 'refund_apple_pay_details',
        cond: (ctx, values) =>
          collectReversalDetails(ctx, values) &&
          paymentMethodIs(ctx, values, 'ApplePay')
      },
      {
        target: 'refund_apple_card_details',
        cond: (ctx, values) =>
          collectReversalDetails(ctx, values) &&
          paymentMethodIs(ctx, values, 'AppleCard')
      },
      {
        target: 'refund_google_pay_details',
        cond: (ctx, values) =>
          collectReversalDetails(ctx, values) &&
          paymentMethodIs(ctx, values, 'GooglePay')
      },
      { target: 'refund_method' },
    ]
  },
  {
    name: 'refund_payment_date',
    next: [
      {
        target: 'refund_card_details',
        cond: (ctx, values) =>
          hasPreAuthPeriodExceeded(ctx, values) &&
          collectReversalDetails(ctx, values) &&
          paymentMethodIs(ctx, values, 'Card'),
      },
      {
        target: 'refund_apple_pay_details',
        cond: (ctx, values) =>
          hasPreAuthPeriodExceeded(ctx, values) &&
          collectReversalDetails(ctx, values) &&
          paymentMethodIs(ctx, values, 'ApplePay')
      },
      {
        target: 'refund_apple_card_details',
        cond: (ctx, values) =>
          hasPreAuthPeriodExceeded(ctx, values) &&
          collectReversalDetails(ctx, values) &&
          paymentMethodIs(ctx, values, 'AppleCard')
      },
      {
        target: 'refund_google_pay_details',
        cond: (ctx, values) =>
          hasPreAuthPeriodExceeded(ctx, values) &&
          collectReversalDetails(ctx, values) &&
          paymentMethodIs(ctx, values, 'GooglePay')
      },
      { target: 'refund_method', cond: hasPreAuthPeriodExceeded },
      { target: 'contact' }
    ]
  },
  {
    name: 'refund_card_details',
    next: [
      { target: 'refund_method', cond: paymentMethodCollectsAllRefundDetails },
      { target: 'contact' }
    ]
  },
  {
    name: 'refund_apple_pay_details',
    next: [
      { target: 'refund_method', cond: paymentMethodCollectsAllRefundDetails },
      { target: 'contact' }
    ]
  },
  {
    name: 'refund_apple_card_details',
    next: [
      { target: 'refund_method', cond: paymentMethodCollectsAllRefundDetails },
      { target: 'contact' }
    ]
  },
  {
    name: 'refund_google_pay_details',
    next: [
      { target: 'refund_method', cond: paymentMethodCollectsAllRefundDetails },
      { target: 'contact' }
    ]
  },
  {
    name: 'refund_method',
    next: [
      { target: 'refund_paypal', cond: (ctx, values) => refundMethodIs(ctx, values, 'paypal') },
      { target: 'refund_venmo', cond: (ctx, values) => refundMethodIs(ctx, values, 'venmo') },
      { target: 'refund_zelle', cond: (ctx, values) => refundMethodIs(ctx, values, 'zelle') },
      { target: 'refund_details', cond: (ctx, values) => refundMethodIs(ctx, values, 'check') },
      { target: 'refund_details', cond: (ctx, values) => refundMethodIs(ctx, values, 'cash') },
      { target: 'refund_vendor_card', cond: (ctx, values) => refundMethodIs(ctx, values, 'vendorcard') },
      { target: 'refund_interac', cond: (ctx, values) => refundMethodIs(ctx, values, 'interac') },
      { target: 'contact' }
    ]
  },
  {
    name: 'refund_paypal',
    next: [
      { target: 'contact' },
    ]
  },
  {
    name: 'refund_venmo',
    next: [
      { target: 'contact' },
    ]
  },
  {
    name: 'refund_zelle',
    next: [
      { target: 'contact' },
    ]
  },
  {
    name: 'refund_details',
    next: [
      { target: 'contact' },
    ]
  },
  {
    name: 'refund_vendor_card',
    next: [
      { target: 'contact' },
    ]
  },
  {
    name: 'refund_interac',
    next: [
      { target: 'refund_interac_question' },
    ]
  },
  {
    name: 'refund_interac_question',
    next: [
      { target: 'refund_interac_answer' },
    ]
  },
  {
    name: 'refund_interac_answer',
    next: [
      { target: 'contact' },
    ]
  },
  {
    name: 'contact',
    submit: true,
    next: [
      { target: 'refund_payrange_details', cond: (ctx, values) => paymentMethodIs(ctx, values, 'PayRange') },
      { target: 'refund_external_details', cond: (ctx, values) => hasPreAuthPeriodExceeded(ctx, values) && refundMethodIs(ctx, values, 'external') },
      { target: 'auth_hold_info', cond: (ctx, values) => !hasPreAuthPeriodExceeded(ctx, values) && paymentMethodHasPreAuth(ctx, values) },
      { target: 'success' },
    ]
  },
  {
    name: 'refund_payrange_details',
    next: [
      { target: 'success' }
    ]
  },
  {
    name: 'refund_external_details',
    next: [
      { target: 'success' }
    ]
  },
  {
    name: 'auth_hold_info',
    next: [
      { target: 'success' }
    ]
  },
  {
    name: 'success',
    next: []
  },
] as FunnelSteps;
