import { useEffect, useRef, useState } from 'react';
import {
  Heading, Button, Box, FormControl, FormLabel, Input, FormErrorMessage, FormHelperText, Text, Link as A
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import TagDetail from 'components/tag_select/TagDetail';
import { Tag, usePageData } from 'lib/hooks/PageDataContext';
import axios from 'lib/api/axios';
import { FunnelSteps, useFunnel } from '../../funnel/Funnel';
import Footer from '../../layout/Footer';
import { HelpFunnelSteps } from '../../funnel/help-funnel-steps';
import { FeedbackFunnelSteps } from '../../funnel/feedback-funnel-steps';
import { Global } from '@emotion/react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useI18n } from '../../../lib/hooks/I18n';
import Notices from '../../notices/Notices';

const SelectorStep = () => {
  const { t, i18n } = useI18n();
  const { stepValues, setFunnel, updateStepValues, goForward } = useFunnel();
  const { token, owner, prefill, requestLink, setPrefill, setNotices, notices, settings } = usePageData();
  const [ tag, setTag ] = useState<Tag | null>(prefill.tag ?? null);
  const [ bgColor, updateBgColor ] = useState<string>('#F1F4F8');
  const [isLoading, setIsLoading] = useState(false);

  const tagRef = useRef<Tag | null>();
  tagRef.current = tag;

  const schema = yup.object({
    machine_id: yup.string().when('$required', (required, schema) => required ? schema.required(t('fields.machine_id.validations.required')) : schema)
  });

  const { register, getValues, handleSubmit, formState: { errors }} = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: stepValues(),
    context: {
      required: owner.require_machine_id_title_flg,
    }
  });

  const handleClick = (steps: FunnelSteps, type: string) => {
    const data = { ...getValues(), type: type.toLowerCase() };

    // timeout and tagRef are a hack to ensure tag data is available before rerendering
    setTimeout(() => {
      if (tagRef.current?.id) {
        delete data['machine_id'];
        data['tag_id'] = tagRef.current?.id;
      }

      setFunnel(steps);
      goForward(data);
    }, 0);

    updateBgColor('#FFF');
  };

  const onTagUpdate = async (tag: Tag) => {
    setTag(tag);
    setPrefill({ tag });
    updateStepValues({ tag_id: tag.id }, 'selector');

    try {
      if (token) {
        await axios(`/api/token/${token?.id}`, { method: 'PUT', body: { tag_id: tag.id } });
      }

      const notices = await axios(`/api/owner/${owner.company_url_slug}/notices?tag_id=${tag?.id || ''}`);

      setNotices(notices);
    } catch (e) {
      console.error(e);
    }
  };

  const handleManualTagID = async () => {
    const machineID = getValues('machine_id');

    if (!machineID) {
      handleClick(HelpFunnelSteps, requestLink?.type ?? 'help');

      return;
    }

    try {
      setIsLoading(true);
      const data = await axios<Tag>(`/api/owner/${owner.company_url_slug}/tag/${machineID}`);

      const _notices = await axios(`/api/owner/${owner.company_url_slug}/notices?tag_id=${data?.id || ''}`);
      setNotices(_notices);

      if (data) {
        setTag(data);
        setPrefill({ tag: data });
        updateStepValues({ tag_id: data.id }, 'selector');
      } else {
        setTag(null);
        setPrefill({ tag: null });
        updateStepValues({ tag_id: null }, 'selector');
      }

      if (notices?.length === _notices?.length) {
        handleClick(HelpFunnelSteps, requestLink?.type ?? 'help');
      }
    } catch (e) {
      setTag(null);
      setPrefill({ tag: null });
      updateStepValues({ tag_id: null }, 'selector');

      handleClick(HelpFunnelSteps, requestLink?.type ?? 'help');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Global styles={`body::after { background: ${bgColor}; }`} />

      <Box pb="4" mb="14" id="SelectorStep">
        <Notices />

        {tag && <>
          <Heading fontSize="xl" mb="4" fontWeight="bold">
            { t('steps.selector.with_tag_title') }
          </Heading>

          <TagDetail tag={tag} onTagUpdate={onTagUpdate} />

          <Button
            variant="solid"
            size="lg"
            type="submit"
            rightIcon={<ArrowForwardIcon boxSize="6"/>}
            onClick={() => handleClick(HelpFunnelSteps, requestLink?.type ?? 'help')}
          >
            { (!requestLink || requestLink?.type === 'Help') && t('steps.selector.buttons.help') }
            { requestLink?.type === 'Refund' && t('steps.selector.buttons.refund') }
          </Button>
        </>}

        {!tag && <>
          <Heading fontSize="xl" mb="4" fontWeight="bold">
            { t('steps.selector.without_tag_title') }
          </Heading>

          <FormControl id="machine_id" isInvalid={!!errors.machine_id}>
            <FormLabel variant="heavy">{ t('fields.machine_id.label') }</FormLabel>
            { i18n.exists('fields.machine_id.text') && <Text fontSize={13} color="gray" mt={-2} mb={3}>{ t('fields.machine_id.text') }</Text>}

            <Input
              type="text"
              autoComplete="off"
              maxLength="150"
              defaultValue={getValues('machine_id')}
              {...register('machine_id')}
            />

            { errors.machine_id ?
              <FormErrorMessage>{ errors.machine_id.message }</FormErrorMessage> :
              <FormHelperText pr="2">
                {
                  owner.require_machine_id_title_flg ?
                    t('fields.machine_id.aside.required_text') :
                    t('fields.machine_id.aside.optional_text')
                }
              </FormHelperText>
            }
          </FormControl>

          <Button
            variant="solid"
            size="lg"
            type="submit"
            isLoading={isLoading}
            rightIcon={<ArrowForwardIcon boxSize="6"/>}
            onClick={handleSubmit(handleManualTagID)}
          >
            { !requestLink && t('common.buttons.continue') }
            { requestLink?.type === 'Help' &&  t('steps.selector.buttons.help') }
            { requestLink?.type === 'Refund' && t('steps.selector.buttons.refund') }
          </Button>
        </>}
      </Box>

      { !settings.feedback_disabled && <>
        <Heading fontSize="xl" mb="4" fontWeight="bold">{ t('steps.selector.more_options_title') }</Heading>

        <A fontSize="17" onClick={() => handleClick(FeedbackFunnelSteps, 'feedback')}>
          { t('steps.selector.buttons.feedback') }
        </A>
      </>}

      <Footer />
    </>
  );
};

export default SelectorStep;
