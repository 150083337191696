import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { STEPS } from './steps-map';
import { AnimatePresence } from 'framer-motion';
import FunnelStepAnimation from './FunnelStepAnimation';
import { useEffectOnceWhen } from 'rooks';
import { PageData, usePageData } from '../../lib/hooks/PageDataContext';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { CompletableFunnelSteps } from './completable-funnel-steps';

export type FunnelStep = {
  name: string;
  submit?: boolean;
  skip?: boolean;
  next: {
    target: string,
    submit?: boolean;
    cond?: (context: PageData, values: unknown) => boolean,
  }[];
  data?: unknown;
};

export type FunnelSteps = FunnelStep[];

export type StepTypes = keyof typeof STEPS;

type FunnelProviderContextProps = {
  setFunnel: (steps: FunnelSteps) => void;
  funnel: FunnelSteps;
  stepValues: (defaultValues?: unknown) => unknown;
  updateStepValues: (values: unknown, stepName: StepTypes) => unknown;
  currentStep: FunnelStep;
  values: unknown;
  goBack: (data: unknown) => void;
  goForward: (data?: unknown) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  direction: number;
};

type Props = {
  initialStep?: FunnelStep;
  initialFunnel?: FunnelSteps;
  initialData: unknown;
  handleSubmit: (data: unknown) => unknown;
};

export const FunnelProviderContext = createContext<FunnelProviderContextProps>(null!);

let funnel: FunnelSteps = [];
const history: FunnelSteps = [];
const completed: FunnelSteps = [];

let _initialData: any = {};
let _completableData: any = {};

export const FunnelProvider = ({ initialStep, initialFunnel = [], initialData, handleSubmit }: Props) => {
  const context = usePageData();
  const [currentStep, setCurrentStep] = useState<FunnelStep>(initialStep || initialFunnel[0] || {});
  const [direction, setDirection] = useState<number>(0);
  const [disableBack, setDisableBack] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState<unknown>(initialData);
  const { getData: getVisitorData } = useVisitorData({extendedResult: true}, {immediate: false});

  const ctxRef = useRef<PageData>();
  ctxRef.current = context;

  useEffectOnceWhen(() => {
    if (initialStep) {
      completed.push(initialStep);
    }
  }, !!initialStep);

  useEffectOnceWhen(() => {
    if (initialFunnel) {
      funnel = completableFunnelSteps(initialFunnel);

      completed.push(initialFunnel[0]);
    }
  }, initialFunnel?.length > 0);

  useEffect(() => {
    _initialData = { ...initialData };
  }, []);

  useEffect(() => {
    setValues(completed.reduce((values, step) => {
      return { ...values, ...step.data };
    }, {..._initialData, ..._completableData}));

    setDisableBack(completed.length === 1);
  }, [completed.length]);

  const completableFunnelSteps = (steps: FunnelSteps) => {
    _completableData = {};

    return steps.map((step) => {
      const completableStep = CompletableFunnelSteps[step.name];

      if (completableStep) {
        const data = completableStep(ctxRef.current);

        if (data) {
          _completableData = {..._completableData, ...data};

          step.skip = true;
          return step;
        }
      }

      step.skip = false;
      return step;
    });
  };

  const setFunnel = (steps: FunnelSteps) => {
    funnel = completableFunnelSteps(steps);
  };

  const stepValues = (defaultValues = {}) => {
    const data = history.find(item => item.name === currentStep.name)?.data;
    return { ...defaultValues, ...data };
  };

  const updateStepValues = (data = {}, stepName) => {
    const step = history.find(item => item.name === stepName);

    if (step) {
      step.data = { ...step.data, ...data };

      // Re-compiles the completed steps data
      setValues(completed.reduce((values, step) => {
        return { ...values, ...step.data };
      }, {..._initialData, ..._completableData}));
    }
  };

  const saveHistory = (step: FunnelStep) => {
    let historyIndex = history.findIndex(item => item.name === step.name);
    if (historyIndex > -1) {
      history[historyIndex] = step;
    } else {
      history.push(step);
    }
  };

  const goBack = (data: unknown) => {
    currentStep.data = data;
    saveHistory(currentStep);

    setDirection(-1);
    setCurrentStep(completed[completed.length - 1]);

    completed.pop();
  };

  const goForward = async (data: unknown = {}) => {
    const current = funnel.find(step => step.name === currentStep.name);
    current.data = data;

    saveHistory(current);
    completed.push(current);

    const nextTarget = findNextTarget(current);
    const nextStep = funnel.find(step => step.name === nextTarget.target);

    if ((current.submit || nextTarget.submit) && handleSubmit) {
      setIsSubmitting(true);
      try {
        const visitorData = await getVisitorData();
        const results = await handleSubmit({ ...values, ...data, visitor: visitorData });

        nextStep.data = results ?? {};
      } finally {
        setIsSubmitting(false);
      }
    }

    setDirection(1);
    setCurrentStep(nextStep);
  }

  const findNextTarget = (step: FunnelStep) => {
    const nextTarget = step.next.find((item) => {
      return item?.cond ? item.cond(ctxRef.current as PageData, { ...values, ...step.data }) : true;
    });

    const nextStep = funnel.find(step => step.name === nextTarget.target);

    if (nextStep.skip) {
      return findNextTarget(nextStep);
    }

    return nextTarget;
  };

  return <FunnelProviderContext.Provider value={{
    setFunnel, funnel, direction, goBack, goForward, stepValues, currentStep,
    updateStepValues, values, disableBack, isSubmitting
  }}>
    <AnimatePresence mode="popLayout" initial={false}>
      <FunnelStepAnimation key={currentStep.name} step={STEPS[currentStep.name]} />
    </AnimatePresence>
  </FunnelProviderContext.Provider>
};

export function useFunnel() {
  const context = useContext(FunnelProviderContext);

  if (!context) {
    throw new Error(
      'useFunnel hook was called outside of FunnelProvider context'
    );
  }

  return context;
}
