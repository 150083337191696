import {
  StackDivider,
  VStack,
  RadioGroup,
  Radio,
  Flex,
  Button,
  FormLabel,
  FormControl
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { usePageData } from 'lib/hooks/PageDataContext';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../funnel/Funnel';
import { useI18n } from '../../../lib/hooks/I18n';
import allowedRequestTypes from '../../helpers/allowed-request-types';

const RequestTypeStep = () => {
  const { t, locale } = useI18n();
  const { stepValues, goForward, goBack } = useFunnel();
  const { prefill, owner, settings } = usePageData();

  const { register, getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    defaultValues: stepValues()
  });

  const [onSubmit] = useThrottle((data: any) => {
    const requestType = owner.request_types.find(type => type.id == data.request_type);
    if (requestType) {
      data.refund_requested = requestType.settings.refundable_type === 'required' && settings.refunds_active;
    }

    goForward(data);
  }, 1000);

  const requestTypes = allowedRequestTypes(owner, prefill);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="request_type" isInvalid={!!errors.request_type}>
        <FormLabel variant="heavy">{ t('fields.request_type.label') }</FormLabel>
        <RadioGroup defaultValue={getValues('request_type')}>
          <VStack
            spacing="0"
            mb="4"
            overflow="hidden"
            backgroundColor="gray.100"
            borderRadius="2xl"
            boxShadow="xl"
            alignItems="left"
            divider={<StackDivider borderColor="gray.300" />}
          >
            {
              requestTypes.map((option, index) =>
                <Radio
                  key={index}
                  value={String(option.id)}
                  px="5" py="4"
                  {...register('request_type', { required: true })}
                >{option.translations?.[locale] ?? option.type}</Radio>
              )
            }
          </VStack>
        </RadioGroup>
      </FormControl>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default RequestTypeStep;
