import { Button, Flex } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';
import { RawHtml } from '../../../layout/RawHtml';
import { usePageData } from '../../../../lib/hooks/PageDataContext';

const ExternalDetailsStep = () => {
  const { t, locale } = useI18n();
  const { owner } = usePageData();
  const { stepValues, values, goForward } = useFunnel();

  const method = owner.refund_methods.find(i => i.id === values.refund_method_id)

  const { handleSubmit, formState: { isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    defaultValues: stepValues(),
  });

  const [onSubmit] = useThrottle(() => {
    goForward();
  }, 1000);

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <RawHtml html={method.translations?.page_content?.[locale] ?? ''} />

      <Flex justifyContent="right" mt="6">
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  </>;
};

export default ExternalDetailsStep;
