import { PageData } from '../../lib/hooks/PageDataContext';
import allowedRequestTypes from '../helpers/allowed-request-types';
import allowedPaymentMethods from '../helpers/allowed-payment-methods';
import allowedRefundMethods from '../helpers/allowed-refund-methods';

export const CompletableFunnelSteps = {
  request_type: ({ owner, prefill, settings }: PageData) => {
    const requestTypes = allowedRequestTypes(owner, prefill);

    if (requestTypes.length === 1) {
      const requestType = requestTypes[0];

      return {
        refund_requested: requestType.settings.refundable_type === 'required' && settings.refunds_active,
        request_type: `${requestType.id}`,
      };
    }
  },
  machine_type: ({ owner }: PageData) => {
    if (owner.machine_types.length === 1) {
      const machineType = owner.machine_types[0];

      return {
        machine_type: `${machineType.id}`,
      };
    }
  },
  refund_payment_method: ({ owner, prefill }: PageData) => {
    const paymentMethods = allowedPaymentMethods(owner, prefill);

    if (paymentMethods.length === 1) {
      const method = paymentMethods[0];

      return {
        refund_payment_method: `${method.id}`
      };
    }
  },
  refund_method: ({ owner, prefill }: PageData) => {
    const refundMethods = allowedRefundMethods(owner, prefill);

    if (refundMethods.length === 1) {
      const method = refundMethods[0];

      return {
        refund_method_id: method.id,
        refund_method: owner.refund_methods.find(i => i.id === method.id)?.type,
      };
    }
  },
};
